import React from "react";
import { HomeCarrouselUI } from "../Home/HomeCarrousel/HomeCarrouselUI";
import { HomeCategories } from "../Home/HomeCategories";
import { HomeTopCategories } from "../Home/HomeTopCategories";
import { NieveEffect } from "../../hooks/NieveEffect";
import { useRef } from "react";

function Home() {
    const canvasRef = useRef(null);
    return (
        <React.Fragment>
            <NieveEffect canvasRef={canvasRef} />
            <HomeCarrouselUI />
            <HomeCategories />
            <HomeTopCategories />
            <canvas ref={canvasRef} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 9999,
                width: '100%',
                height: '100%',
                pointerEvents: 'none'
            }} />
        </React.Fragment>
    )
}

export { Home }