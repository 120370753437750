import React from "react";
import { HomeMenuUI } from "./HomeMenuUI";

function HomeMenu() {
    
    return (
        <HomeMenuUI />
    );
}

export { HomeMenu }