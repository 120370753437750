import React from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { HomeLogoUI } from "../HomeLogo/HomeLogoUI";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { COLORS } from "../../../assets/colors";
import '../HomeSearch/HomeSearch.css';
import '../HomeSearch';
import 'typeface-quicksand';
import { useState, useRef, useEffect } from "react";
import audioURL from '../../../assets/sound/navidad.mp3';

/*
xs: Extra pequeño (0 a 600px)
sm: Pequeño (600px a 960px)
md: Mediano (960px a 1280px)
lg: Grande (1280px a 1920px)
xl: Extra grande (1920px en adelante)
*/


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '100%',
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(3),
        width: '40%',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.secondary,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: COLORS.secondary,
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));


function HomeSearchUI({
    open,
    setOpen
}) {
    const matches = useMediaQuery('(min-width:600px)');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [audioPlaying, setAudioPlaying] = useState(false);

    const audioRef = useRef(new Audio(audioURL));

    useEffect(() => {
        const audio = audioRef.current;

        audio.loop = true;

        if (audioPlaying) {
            audio.play();
        } else {
            audio.pause();
        }

        audio.addEventListener('ended', () => {
            audio.currentTime = 0; 
            audio.play();
        });

        return () => {
            audio.pause();
            audio.removeEventListener('ended', () => {});
        };
    }, [audioPlaying]);

    const toggleAudio = () => {
        setAudioPlaying(!audioPlaying);
    };

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const volumeIcon = audioPlaying ? <VolumeUpIcon /> :  <VolumeOffIcon />;

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            PaperProps={{ style: { backgroundColor: COLORS.primary } }}
        >
            <MenuItem style={{ color: COLORS.secondary }} onClick={handleMenuClose}>Perfil</MenuItem>
            <MenuItem style={{ color: COLORS.secondary }} onClick={handleMenuClose}>Pedidos</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            PaperProps={{ style: { backgroundColor: COLORS.primary } }}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" style={{ color: COLORS.secondary }}>
                    <Badge badgeContent={4} classes={{ badge: 'myCustomBadge' }}>
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p style={{ color: COLORS.secondary }}>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    style={{ color: COLORS.secondary }}
                >
                    <Badge badgeContent={17} classes={{ badge: 'myCustomBadge' }}>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p style={{ color: COLORS.secondary }}>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    style={{ color: COLORS.secondary }}
                >
                    <AccountCircle />
                </IconButton>
                <p style={{ color: COLORS.secondary }}>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{
                backgroundColor: COLORS.primary,
            }}>
                <Toolbar style={{ height: 130 }}>
                    {
                        matches && (<IconButton
                            style={{ color: COLORS.secondary, marginLeft: 10 }}
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>)
                    }
                    {!open && (<HomeLogoUI visible={true} />)}
                    <Box sx={{ flexGrow: 1 }} />
                    <Search
                        sx={{ display: 'none' }}>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder={matches ? 'Encuentra tus productos favoritos' : 'Buscar'}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Box >
                        <IconButton sx={{ display: { xs: 'none', md: 'none' } }} size="large" aria-label="show 4 new mails" style={{ color: COLORS.secondary }}>
                            <Badge badgeContent={4} classes={{ badge: 'myCustomBadge' }}>
                                <FavoriteIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            sx={{ display: { xs: 'none', md: 'none' } }}
                            size="large"
                            aria-label="show 17 new notifications"
                            style={{ color: COLORS.secondary }}
                        >
                            <Badge badgeContent={17} classes={{ badge: 'myCustomBadge' }}>
                                <ShoppingCartIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={toggleAudio}
                            style={{ color: COLORS.secondary }}
                        >
                            {volumeIcon}
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </Box>
    );
}

export { HomeSearchUI };