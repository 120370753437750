import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import { HomeMenu } from "../Home/HomeMenu";
import { HomeBottomNavigation } from "../Home/HomeBottomNavigation";
import { Home } from "../Home";
import { ROUTES_PATH } from "../../utils/Consts";
import { Categories } from "../Categories";
import { Route, Routes } from "react-router-dom";
import Box from '@mui/material/Box';
import { HomePrivacyPolicies } from "../Home/HomePrivacyPolicies";

function AppUI() {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <div>
            {matches ? <HomeMenu /> : <HomeBottomNavigation />}
            <Box component="main" sx={{
                marginTop: { xs: 0, sm: '130px',md: '130px', lg: '130px', xl: '130px' },
                marginLeft: { xs: 0, sm: 0, md: '7%', lg: '7%', xl: '5%' }
            }}>
                <Routes>
                    <Route path={ROUTES_PATH.HOME} element={<Home />} />
                    <Route path={`${ROUTES_PATH.CATEGORIES}/:categoryId`} element={<Categories />} />
                    <Route path={ROUTES_PATH.ALL} element={<Home />} />
                    <Route path={ROUTES_PATH.PRIVACYPOLICY} element={<HomePrivacyPolicies />} />
                </Routes>
            </Box>
        </div>
    )
}

export { AppUI };