import React from "react";
import { HomeSearchUI } from "../HomeSearch/HomeSearchUI";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CakeIcon from '@mui/icons-material/Cake';
import InfoIcon from '@mui/icons-material/Info';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { COLORS } from "../../../assets/colors";
import { HomeLogoUI } from "../HomeLogo/HomeLogoUI";
import { routesMenu, routes } from "../../../utils/Consts";
import { NavLink, useLocation } from "react-router-dom";



const drawerWidth = 280;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(12)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const drawerHeaderStyles = {
    minHeight: '130px',
    backgroundColor: COLORS.primary,
};



function HomeMenuUI() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const location = useLocation();

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <HomeSearchUI
                    open={open}
                    setOpen={setOpen}
                />
            </AppBar>
            <Drawer variant="permanent" open={open} >
                <DrawerHeader style={drawerHeaderStyles}>
                    <HomeLogoUI visible={false} />
                    <IconButton onClick={handleDrawerClose} style={{ color: COLORS.secondary }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider style={{ backgroundColor: COLORS.secondary }} />
                <List style={{ backgroundColor: COLORS.primary }}>
                    {routesMenu.map((route, index) => (
                        <ListItem key={route.text} disablePadding sx={{ display: 'block' }}>

                            <NavLink to={route.to} style={{ textDecoration: 'none' }} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        color: location.pathname === route.to ? 'white' : COLORS.secondary,
                                        px: 2.5
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            paddingLeft: '15px'
                                        }}
                                    >
                                        {index % 2 === 0 ?
                                            <HomeIcon style={{
                                                color: location.pathname === route.to ? 'white' : COLORS.secondary
                                            }} /> : <InfoIcon style={{
                                                color: location.pathname === route.to ? 'white' : COLORS.secondary
                                            }} />}
                                    </ListItemIcon>

                                    <ListItemText primary={route.text} sx={{
                                        opacity: open ? 1 : 0, display: open ? 'block' : 'none'
                                    }} />
                                </ListItemButton>
                            </NavLink>


                        </ListItem>
                    ))}
                </List>
                <Divider style={{ backgroundColor: COLORS.secondary }} />
                <List style={{
                    backgroundColor: COLORS.primary,
                    height: '100%',
                }}>
                    {routes.map((route, index) => (
                        <ListItem key={route.text} disablePadding sx={{ display: 'block' }}>
                            <NavLink to={route.to} style={{ textDecoration: 'none' }} >
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        color: COLORS.secondary,
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                            paddingLeft: '15px'
                                        }}
                                    >
                                        {index % 2 === 0 ? <CakeIcon
                                            style={{
                                                color: location.pathname === route.to ? 'white' : COLORS.secondary
                                            }} /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={route.text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </NavLink>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </Box>
    );
}

export { HomeMenuUI }