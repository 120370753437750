import React from "react";
import { CategoriesModalUI } from "./CategoriesModalUI";

function CategoriesModal({
    open,
    setOpen,
    data    
}) {
    
    return (
        <CategoriesModalUI
            open={open}
            setOpen={setOpen}
            data={data}
        />
    )

}

export { CategoriesModal }