import React from "react";

function HomePrivacyPoliciesUI() {
    const divStyle = {
        padding: '20px' 
    };
    return (
        <div style={divStyle}>
            <h1>POLITICA DE TRATAMIENTO DE DATOS PERSONALES DE
                PANADERIA Y PASTELERIA EL ROSAL </h1>
            <p>De conformidad con la normatividad vigente sobre el tratamiento de datos personales, autorizo de manera previa, expresa e informada a PANADERIA Y PASTELERIA EL ROSAL, para que recolecte, almacene, utilice, administre y transfiera a terceros mis datos personales, para el cumplimiento de sus relaciones laborales, comerciales y su objeto social, de acuerdo con la Política de Tratamiento de Datos Personales, la cual se me dio a conocer antes de recolectar mis datos personales y se encuentra disponible en las oficinas principales de PANADERIA Y PASTELERIA EL ROSAL ubicadas en la Carrera  78 No. 8 A 25 Barrio Castilla de Bogotá D.C. y en la página web https://www.XXXX
                Declaro conocer que los datos personales objeto de tratamiento serán utilizados para las siguientes finalidades: (i) Expedición de facturas en virtud de la adquisición de productos y/o servicios; (ii) Procesamiento de pagos y órdenes realizados a través de la página web de PANADERIA Y PASTELERIA EL ROSAL e informes sobre el estado de dichas transacciones; (iii) Facilitar la correcta ejecución de la relación comercial; (iv) Atención de inquietudes y respuesta de peticiones, quejas y reclamos; (v) Entregar los productos adquiridos; (vi) Desarrollar estrategias comerciales, organizacionales, de publicidad y de mercadeo, así como programas de fidelización de clientes; (vii) Informar sobre ofertas, eventos y promociones; (viii) Ofrecer los productos y servicios de PANADERIA Y PASTELERIA EL ROSAL directamente o a través de terceros; (ix) Llevar estadísticas internas con fines comerciales; (x) Realizar estudios con el fin de mejorar los servicios de PANADERIA Y PASTELERIA EL ROSAL y la experiencia de los clientes; (xi) Realizar cualquier tipo de actuación administrativa, contable, de cobro y/o judicial, derivada de la relación comercial; (xii) Gestionar, consultar y reportar la información comercial, crediticia y financiera en centrales de riesgo y/o bases de datos; (xiii) Implementar medidas encaminadas a la detección de fraude; (xiv) Dar cumplimiento a la normatividad vigente; y (xv) Suministrar la información a terceros con los cuales PANADERIA Y PASTELERIA EL ROSAL tenga una relación contractual y que sea necesario entregársela para el cumplimiento del objeto contratado.
                Adicionalmente, declaro que me han sido informados y conozco mis derechos a (i) acceder en forma gratuita a los datos proporcionados objeto de tratamiento; (ii)  conocer, actualizar y rectificar mis datos personales; (iii) ser informado sobre el uso que se ha dado a los mismos; (iv) solicitar prueba de la autorización otorgada; (v)  presentar quejas ante la Superintendencia de Industria y Comercio (SIC) por infracciones a la normatividad vigente; (vi)  revocar la autorización y/o solicitar la supresión de mis datos en los casos que sea procedente; y (vii) abstenerme de responder las preguntas sobre datos sensibles o de menores de edad, los cuales puedo hacer efectivos mediante una comunicación escrita dirigida al correo electrónico XXXXXXX
                A su vez, declaro que conozco que cualquier actualización y modificación sustancial a la Política de Tratamiento de Datos Personales será informada mediante publicación en las oficinas principales de PANADERIA Y PASTELERIA EL ROSAL o en nuestra página web.
                Como evidencia del conocimiento de la anterior información, al elegir la opción “Autorizo” estoy (i) autorizando el tratamiento de mis datos personales de la forma previamente descrita; (ii) manifestando que la presente autorización me fue solicitada y puesta de presente antes de entregar mis datos; (iii) manifestando que la acepto de forma libre y voluntaria, una vez leída en su totalidad; (iv)  declarando que los datos suministrados a PANADERIA Y PASTELERIA EL ROSAL son ciertos, completos, exactos, actualizados, comprobables y comprensibles.
                En el evento que el Titular de los datos personales sea un niño, niña o adolescente, el representante legal será quien otorgue la autorización, previo ejercicio del menor de su derecho a ser escuchado, opinión que será valorada teniendo en cuenta la madurez, autonomía y capacidad para entender el asunto.
                La presente Política de Tratamiento de Datos Personales empieza a regir a partir del día XX de  XX del año dos mil XX (20XX).</p>
            <h2>INFORMACIÓN GENERAL</h2>
            <p>Este sitio web es operado por PANADERIA Y PASTELERIA EL ROSAL. En todo el sitio, los términos “nosotros”, “nos” y “nuestro” se refieren a PANADERIA Y PASTELERIA EL ROSAL.
                PANADERIA Y PASTELERIA EL ROSAL ofrece este sitio web, incluyendo toda la información, herramientas y servicios disponibles para ti en este sitio, el usuario, está condicionado a la aceptación de todos los términos, condiciones, políticas y notificaciones aquí establecidos.
                Al visitar nuestro sitio y/o comprar algo de nosotros, participas en nuestro “Servicio” y aceptas los siguientes términos y condiciones (“Términos de Servicio”, “Términos”), incluidos todos los términos y condiciones adicionales y las políticas a las que se hace referencia en el presente documento y/o disponible a través de hipervínculos. Estas Condiciones de Servicio se aplican a todos los usuarios del sitio, incluyendo si limitación a usuarios que sean navegadores, proveedores, clientes, comerciantes, y/o colaboradores de contenido.
                Por favor, lee estos Términos de Servicio cuidadosamente antes de acceder o utilizar nuestro sitio web. Al acceder o utilizar cualquier parte del sitio, estás aceptando los Términos de Servicio. Si no estás de acuerdo con todos los términos y condiciones de este acuerdo, entonces no deberías acceder a la página web o usar cualquiera de los servicios. Si las Términos de Servicio son considerados una oferta, la aceptación está expresamente limitada a estos Términos de Servicio.
                Cualquier función nueva o herramienta que se añadan a la tienda actual, también estarán sujetas a los Términos de Servicio. Puedes revisar la versión actualizada de los Términos de Servicio, en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los Términos de Servicio mediante la publicación de actualizaciones y/o cambios en nuestro sitio web. Es tu responsabilidad chequear esta página periódicamente para verificar cambios. Tu uso contínuo o el acceso al sitio web después de la publicación de cualquier cambio constituye la aceptación de dichos cambios.</p>
            <h2>1 - TÉRMINOS DE LA TIENDA EN LÍNEA</h2>
            <p>Al utilizar este sitio, declaras que tienes al menos la mayoría de edad y que nos has dado tu consentimiento para permitir que cualquiera de tus dependientes menores use este sitio.
                No puedes usar nuestros productos con ningún propósito ilegal o no autorizado tampoco puedes, en el uso del Servicio, violar cualquier ley en tu jurisdicción (incluyendo pero no limitado a las leyes de derecho de autor).
                No debes transmitir gusanos, virus o cualquier código de naturaleza destructiva.
                El incumplimiento o violación de cualquiera de estos Términos darán lugar al cese inmediato de tus Servicios.</p>
            <h2>2 - CONDICIONES GENERALES</h2>
            <p>Nos reservamos el derecho de rechazar la prestación de servicio a cualquier persona, por cualquier motivo y en cualquier momento.
                Entiendes que tu contenido (sin incluir la información de tu tarjeta de crédito), puede ser transferida sin encriptar e involucrar (a) transmisiones a través de varias redes; y (b) cambios para ajustarse o adaptarse a los requisitos técnicos de conexión de redes o dispositivos. La información de tarjetas de crédito está siempre encriptada durante la transferencia a través de las redes.
                Estás de acuerdo con no reproducir, duplicar, copiar, vender, revender o explotar cualquier parte del Servicio, o acceso al Servicio o cualquier contacto en el sitio web a través del cual se presta el servicio, sin el expreso permiso por escrito de nuestra parte.
                Los títulos utilizados en este</p>
            <h2>3 - PRECISIÓN, EXHAUSTIVIDAD Y ACTUALIDAD DE LA INFORMACIÓN</h2>
            <p>No nos hacemos responsables si la información disponible en este sitio no es exacta, completa o actual. El material en este sitio es provisto solo para información general y no debe confiarse en ella o utilizarse como la única base para la toma de decisiones sin consultar primeramente, información más precisa, completa u oportuna. Cualquier dependencia en el materia de este sitio es bajo su propio riesgo.
                Este sitio puede contener cierta información histórica. La información histórica, no es necesariamente actual y es provista únicamente para tu referencia. Nos reservamos el derecho de modificar los contenidos de este sitio en cualquier momento, pero no tenemos obligación de actualizar cualquier información en nuestro sitio. Aceptas que es tu responsabilidad de monitorear los cambios en nuestro sitio.</p>
            <h2>4 - MODIFICACIONES AL SERVICIO Y PRECIOS</h2>
            <p>Los precios de nuestros productos están sujetos a cambio sin aviso.
                Nos reservamos el derecho de modificar o discontinuar el Servicio (o cualquier parte del contenido) en cualquier momento sin aviso previo.
                No seremos responsables ante ti o alguna tercera parte por cualquier modificación, cambio de precio, suspensión o discontinuidad del Servicio.</p>
            <h2>5 - PRODUCTOS O SERVICIOS (si aplicable)</h2>
            <p>Ciertos productos o servicios pueden estar disponibles exclusivamente en línea a través del sitio web. Estos productos o servicios pueden tener cantidades limitadas y estar sujetas a devolución o cambio de acuerdo a nuestra política de devolución solamente.
                Hemos hecho el esfuerzo de mostrar los colores y las imágenes de nuestros productos, en la tienda, con la mayor precisión de colores posible. No podemos garantizar que el monitor de tu computadora muestre los colores de manera exacta.
                Nos reservamos el derecho, pero no estamos obligados, para limitar las ventas de nuestros productos o servicios a cualquier persona, región geográfica o jurisdicción. Podemos ejercer este derecho basados en cada caso. Nos reservamos el derecho de limitar las cantidades de los productos o servicios que ofrecemos. Todas las descripciones de productos o precios de los productos están sujetos a cambios en cualquier momento sin previo aviso, a nuestra sola discreción. Nos reservamos el derecho de discontinuar cualquier producto en cualquier momento. Cualquier oferta de producto o servicio hecho en este sitio es nulo donde esté prohibido.
                No garantizamos que la calidad de los productos, servicios, información u otro material comprado u obtenido por ti cumpla con tus expectativas, o que cualquier error en el Servicio será corregido.</p>
            <h2>6 - EXACTITUD DE FACTURACIÓN E INFORMACIÓN DE CUENTA</h2>
            <p>Nos reservamos el derecho de rechazar cualquier pedido que realice con nosotros. Podemos, a nuestra discreción, limitar o cancelar las cantidades compradas por persona, por hogar o por pedido. Estas restricciones pueden incluir pedidos realizados por o bajo la misma cuenta de cliente, la misma tarjeta de crédito, y/o pedidos que utilizan la misma facturación y/o dirección de envío.
                En el caso de que hagamos un cambio o cancelemos una orden, podemos intentar notificarte poniéndonos en contacto vía correo electrónico y/o dirección de facturación / número de teléfono proporcionado en el momento que se hizo pedido. Nos reservamos el derecho de limitar o prohibir las órdenes que, a nuestro juicio, parecen ser colocado por los concesionarios, revendedores o distribuidores.
                Te comprometes a proporcionar información actual, completa y precisa de la compra y cuenta utilizada para todas las compras realizadas en nuestra tienda. Te comprometes actualizar rápidamente tu cuenta y otra información, incluyendo tu dirección de correo electrónico y números de tarjetas de crédito y fechas de vencimiento, para que podamos completar tus transacciones y contactarte cuando sea necesario.
                Para más detalles, por favor revisa nuestra Política de Devoluciones.
                Es posible que te proporcionemos acceso a herramientas de terceros a los cuales no monitoreamos y sobre los que no tenemos control ni entrada.
                Reconoces y aceptas que proporcionamos acceso a este tipo de herramientas «tal cual» y «según disponibilidad» sin garantías, representaciones o condiciones de ningún tipo y sin ningún respaldo. No tendremos responsabilidad alguna derivada de o relacionada con tu uso de herramientas proporcionadas por terceras partes.
                Cualquier uso que hagas de las herramientas opcionales que se ofrecen a través del sitio bajo tu propio riesgo y discreción y debes asegurarte de estar familiarizado y aprobar los términos bajo los cuales estas herramientas son proporcionadas por el o los proveedores de terceros.
                También es posible que, en el futuro, te ofrezcamos nuevos servicios y/o características a través del sitio web (incluyendo el lanzamiento de nuevas herramientas y recursos). Estas nuevas características y/o servicios también estarán sujetos a estos Términos de Servicio.</p>
            <h2>7 - ENLACES DE TERCERAS PARTES</h2>
            <p>Cierto contenido, productos y servicios disponibles vía nuestro Servicio puede incluir material de terceras partes.Enlaces de terceras partes en este sitio pueden direccionarte a sitios web de terceras partes que no están afiliadas con nosotros. No nos responsabilizamos de examinar o evaluar el contenido o exactitud y no garantizamos ni tendremos ninguna obligación o responsabilidad por cualquier material de terceros o sitos web, o de cualquier material, productos o servicios de terceros.
                No nos hacemos responsables de cualquier daño o daños relacionados con la adquisición o utilización de bienes, servicios, recursos, contenidos, o cualquier otra transacción realizadas en conexión con sitios web de terceros. Por favor revisa cuidadosamente las políticas y prácticas de terceros y asegúrate de entenderlas antes de participar en cualquier transacción. Quejas, reclamos, inquietudes o preguntas con respecto a productos de terceros deben ser dirigidas a la tercera parte.</p>
            <h2>8 - COMENTARIOS DE USUARIO, CAPTACIÓN Y OTROS ENVÍOS</h2>
            <p>Si, a pedido nuestro, envías ciertas presentaciones específicas (por ejemplo la participación en concursos) o sin un pedido de nuestra parte envías ideas creativas, sugerencias, proposiciones, planes, u otros materiales, ya sea en línea, por email, por correo postal, o de otra manera (colectivamente, ‘comentarios’), aceptas que podamos, en cualquier momento, sin restricción, editar, copiar, publicar, distribuír, traducir o utilizar por cualquier medio comentarios que nos hayas enviado. No tenemos ni tendremos ninguna obligación (1) de mantener ningún comentario confidencialmente; (2) de pagar compensación por comentarios; o (3) de responder a comentarios.
                Nosotros podemos, pero no tenemos obligación de, monitorear, editar o remover contenido que consideremos sea ilegítimo, ofensivo, amenazante, calumnioso, difamatorio, pornográfico, obsceno u objetable o viole la propiedad intelectual de cualquiera de las partes o los Términos de Servicio.
                Aceptas que tus comentarios no violarán los derechos de terceras partes, incluyendo derechos de autor, marca, privacidad, personalidad u otro derechos personal o de propiedad. Asimismo, aceptas que tus comentarios no contienen material difamatorio o ilegal, abusivo u obsceno, o contienen virus informáticos u otro malware que pudiera, de alguna manera, afectar el funcionamiento del Servicio o de cualquier sitio web relacionado. No puedes utilizar una dirección de correo electrónico falsa, usar otra identidad que no sea legítima, o engañar a terceras partes o a nosotros en cuanto al origen de tus comentarios. Tu eres el único responsable por los comentarios que haces y su precisión. No nos hacemos responsables y no asumimos ninguna obligación con respecto a los comentarios publicados por ti o cualquier tercer parte.</p>
            <h2>9 - INFORMACIÓN PERSONAL</h2>
            <p>Tu presentación de información personal a través del sitio se rige por nuestra Política de Privacidad. Para ver nuestra Política de Privacidad.</p>
            <h2>10 - ERRORES, INEXACTITUDES Y OMISIONES</h2>
            <p>De vez en cuando puede haber información en nuestro sitio o en el Servicio que contiene errores tipográficos, inexactitudes u omisiones que puedan estar relacionadas con las descripciones de productos, precios, promociones, ofertas, gastos de envío del producto, el tiempo de tránsito y la disponibilidad. Nos reservamos el derecho de corregir los errores, inexactitudes u omisiones y de cambiar o actualizar la información o cancelar pedidos si alguna información en el Servicio o en cualquier sitio web relacionado es inexacta en cualquier momento sin previo aviso (incluso después de que hayas enviado tu orden) .
                No asumimos ninguna obligación de actualizar, corregir o aclarar la información en el Servicio o en cualquier sitio web relacionado, incluyendo, sin limitación, la información de precios, excepto cuando sea requerido por la ley. Ninguna especificación actualizada o fecha de actualización aplicada en el Servicio o en cualquier sitio web relacionado, debe ser tomada para indicar que toda la información en el Servicio o en cualquier sitio web relacionado ha sido modificado o actualizado.</p>
            <h2>11 - USOS PROHIBIDOS</h2>
            <p>En adición a otras prohibiciones como se establece en los Términos de Servicio, se prohibe el uso del sitio o su contenido: (a) para ningún propósito ilegal; (b) para pedirle a otros que realicen o participen en actos ilícitos; (c) para violar cualquier regulación, reglas, leyes internacionales, federales, provinciales o estatales, u ordenanzas locales; (d) para infringir o violar el derecho de propiedad intelectual nuestro o de terceras partes; (e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, orientación sexual, religión, etnia, raza, edad, nacionalidad o discapacidad; (f) para presentar información falsa o engañosa; (g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que sea o pueda ser utilizado en cualquier forma que pueda comprometer la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, otros sitios o Internet; (h) para recopilar o rastrear información personal de otros; (i) para generar spam, phish, pharm, pretext, spider, crawl, or scrape; (j) para cualquier propósito obsceno o inmoral; o (k) para interferir con o burlar los elementos de seguridad del Servicio o cualquier sitio web relacionado¿ otros sitios o Internet. Nos reservamos el derecho de suspender el uso del Servicio o de cualquier sitio web relacionado por violar cualquiera de los ítems de los usos prohibidos.</p>
            <h2>12 - EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD</h2>
            <p>No garantizamos ni aseguramos que el uso de nuestro servicio será ininterrumpido, puntual, seguro o libre de errores.
                No garantizamos que los resultados que se puedan obtener del uso del servicio serán exactos o confiables.
                Aceptas que de vez en cuando podemos quitar el servicio por períodos de tiempo indefinidos o cancelar el servicio en cualquier momento sin previo aviso.
                Aceptas expresamente que el uso de, o la posibilidad de utilizar, el servicio es bajo tu propio riesgo. El servicio y todos los productos y servicios proporcionados a través del servicio son (salvo lo expresamente manifestado por nosotros) proporcionados «tal cual» y «según esté disponible» para su uso, sin ningún tipo de representación, garantías o condiciones de ningún tipo, ya sea expresa o implícita, incluídas todas las garantías o condiciones implícitas de comercialización, calidad comercializable, la aptitud para un propósito particular, durabilidad, título y no infracción.
                En ningún caso Comercializadora Santa Clara S.A., propietaria de la marca Repostería Claire, nuestros directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, pérdida, reclamo, o daños directos, indirectos, incidentales, punitivos, especiales o consecuentes de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo, o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad estricta o de otra manera, como consecuencia del uso de cualquiera de los servicios o productos adquiridos mediante el servicio, o por cualquier otro reclamo relacionado de alguna manera con el uso del servicio o cualquier producto, incluyendo pero no limitado, a cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurridos como resultados de la utilización del servicio o cualquier contenido (o producto) publicado, transmitido, o que se pongan a disposición a través del servicio, incluso si se avisa de su posibilidad. Debido a que algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuenciales o incidentales, en tales estados o jurisdicciones, nuestra responsabilidad se limitará en la medida máxima permitida por la ley.</p>
            <h2>13 - INDEMNIZACIÓN</h2>
            <p>Aceptas indemnizar, defender y mantener indemne PANADERIA Y PASTELERIA EL ROSAL y nuestras matrices, subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, concesionarios, proveedores de servicios, subcontratistas, proveedores, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, hechos por cualquier tercero a causa o como resultado de tu incumplimiento de las Condiciones de Servicio o de los documentos que incorporan como referencia, o la violación de cualquier ley o de los derechos de u tercero.</p>
            <h2>14 - DIVISIBILIDAD</h2>
            <p>En el caso de que se determine que cualquier disposición de estas Condiciones de Servicio sea ilegal, nula o inejecutable, dicha disposición será, no obstante, efectiva a obtener la máxima medida permitida por la ley aplicable, y la parte no exigible se considerará separada de estos Términos de Servicio, dicha determinación no afectará la validez de aplicabilidad de las demás disposiciones restantes.</p>
            <h2>15 - RESCISIÓN</h2>
            <p>Las obligaciones y responsabilidades de las partes que hayan incurrido con anterioridad a la fecha de terminación sobrevivirán a la terminación de este acuerdo a todos los efectos.
                Estas Condiciones de servicio son efectivos a menos que y hasta que sea terminado por ti o nosotros. Puedes terminar estos Términos de Servicio en cualquier momento por avisarnos que ya no deseas utilizar nuestros servicios, o cuando dejes de usar nuestro sitio.
                Si a nuestro juicio, fallas, o se sospecha que haz fallado, en el cumplimiento de cualquier término o disposición de estas Condiciones de Servicio, también podemos terminar este acuerdo en cualquier momento sin previo aviso, y seguirás siendo responsable de todos los montos adeudados hasta incluida la fecha de terminación; y/o en consecuencia podemos negarte el acceso a nuestros servicios (o cualquier parte del mismo).</p>
            <h2>16 - ACUERDO COMPLETO</h2>
            <p>Nuestra falla para ejercer o hacer valer cualquier derecho o disposición de estas Condiciones de Servicio no constituirá una renuncia a tal derecho o disposición.
                Estas condiciones del servicio y las políticas o reglas de operación publicadas por nosotros en este sitio o con respecto al servicio constituyen el acuerdo completo y el entendimiento entre tu y nosotros y rigen el uso del Servicio y reemplaza cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sea oral o escrita, entre tu y nosotros (incluyendo, pero no limitado a, cualquier versión previa de los Términos de Servicio).
                Cualquier ambigüedad en la interpretación de estas Condiciones del servicio no se interpretarán en contra del grupo de redacción.</p>
            <h2>17 - LEY</h2>
            <p>Estas Condiciones del servicio y cualquier acuerdos aparte en el que te proporcionemos servicios se regirán e interpretarán en conformidad con las leyes vigentes en Colombia</p>
            <h2>18 - CAMBIOS EN LOS TÉRMINOS DE SERVICIO</h2>
            <p>Puedes revisar la versión más actualizada de los Términos de Servicio en cualquier momento en esta página.
                Nos reservamos el derecho, a nuestra sola discreción, de actualizar, modificar o reemplazar cualquier parte de estas Condiciones del servicio mediante la publicación de las actualizaciones y los cambios en nuestro sitio web. Es tu responsabilidad revisar nuestro sitio web periódicamente para verificar los cambios. El uso contínuo de o el acceso a nuestro sitio Web o el Servicio después de la publicación de cualquier cambio en estas Condiciones de servicio implica la aceptación de dichos cambios.</p>
            <h2>19 - INFORMACIÓN DE CONTACTO</h2>
            <p>Preguntas acerca de los Términos de Servicio deben ser enviadas al correo elrosalpanaderia7@gmail.com
            </p>
        </div>
    );
}

export { HomePrivacyPoliciesUI };