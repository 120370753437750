import React from "react";
import { Paper } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import '../CategoriesImages/CategoriesImage.css'
import useMediaQuery from '@mui/material/useMediaQuery';

const carouselStyle = {
    backgroundColor: "#ff000", 
    boxShadow: "none" 
}

function CategoriesImageUI({
    details
}) {
    return (
        <div>
            
            <Carousel indicatorContainerProps={{}} interval={1500} autoPlay={true} style={carouselStyle}>
                {
                    details.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>
        </div>

    )
}

function Item(props) {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Paper>
            {
                matches && (
                    <img src={props.item.image} alt={props.item.name}
                        style={{ width: "100%", height: "400px" }} loading="lazy" />
                )
            }
            {
                !matches && (
                    <img src={props.item.image} alt={props.item.name}
                        style={{ width: "100%", height: "250px" }} loading="lazy" />
                )
            
            }
        </Paper>
    )
}

export { CategoriesImageUI }