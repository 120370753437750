import React from "react";
import Card from '@mui/material/Card';
import categories from '../../../assets/img/categories.png';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../../assets/colors';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import '../CategoriesHeader/CategoriesHeader.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
}));

function CategoriesHeaderUI({
    data
}) {
    return (
        <Card sx={{
            width: { xs: "90%", sm: "90%", md: "95%", lg: "95%", xl: "95%" },
            height: { xs: 90, sm: 90, md: 120, lg: 120, xl: 120 },
            backgroundColor: 'white',
            margin: { xs: "100px 0px 20px 20px", sm: "100px 0px 20px 50px", md: "180px 0px 20px 50px", lg: "180px 0px 20px 50px", xl: "180px 0px 20px 50px" },
            borderRadius: "20px",
            boxShadow: "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)",
            position: 'relative',
        }}>

            <Grid container spacing={2}
                sx={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    padding: '0.5rem',
                    color: 'white',
                    backgroundColor: 'white',
                    borderRadius: "20px",
                    zIndex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="title" component="h1"
                    sx={{
                        color: COLORS.primary,
                        fontFamily: 'Quicksand',
                        fontSize: { xs: '3rem', sm: '3rem', md: '4rem', lg: '4rem', xl: '4rem' },
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    {data}
                </Typography>

            </Grid>
        </Card>
    );
}

export { CategoriesHeaderUI };