import React from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import '../Pagination/Pagination.css'


function PaginationUI({
    setCurrentPage,
    totalPage
}) {
    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        console.log(page);
      };
    return (
        <Stack spacing={2}>
            <Pagination count={totalPage} size="large" onChange={handlePageChange}  sx={{padding: "20px 50px"}}/>
        </Stack>
    );
}

export { PaginationUI };