import React from "react";
import logo from '../../../assets/img/logo.png';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../HomeSearch/HomeSearch.css';
import { Box } from "@mui/material";
import { ROUTES_PATH } from "../../../utils/Consts";
import { useNavigate } from 'react-router-dom';

function HomeLogoUI({
    visible
}) {

    const navigate = useNavigate();
    const theme = createTheme({
        typography: {
            fontFamily: 'Quicksand',
            title: {
                fontSize: 32,
                fontFamily: 'Quicksand',
                color: 'transparent', 
                textAlign: 'center',
                fontWeight: 'bold', 
                textTransform: 'uppercase',
                backgroundImage: 'linear-gradient(45deg, #FFD700, #FFA500)',
                WebkitBackgroundClip: 'text', 
                backgroundClip: 'text', 
                animation: 'shine 5s linear infinite',
            },
            description: {
                fontSize: 20,
                fontFamily: 'Quicksand',
                color: 'transparent', 
                textAlign: 'center',
                fontWeight: 'bold', 
                textTransform: 'uppercase',
                backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                WebkitBackgroundClip: 'text', 
                backgroundClip: 'text', 
                animation: 'shine 5s linear infinite',
            },
            subDescription: {
                fontSize: 16,
                fontFamily: 'Quicksand',
                color: 'transparent', 
                textAlign: 'center',
                fontWeight: 'bold', 
                textTransform: 'uppercase',
                backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                WebkitBackgroundClip: 'text', 
                backgroundClip: 'text', 
                animation: 'shine 5s linear infinite',
            }
        },
    });


    return (
        <Box style={{ display: 'contents' }}>
            <img className="Logo"
                src={logo}
                alt="Logo Panderia"
                loading="lazy"
                onClick={() => navigate(ROUTES_PATH.HOME)}
                style={{ cursor: 'pointer' }}
            />
            {visible && (<ThemeProvider theme={theme}>
                <div style={{ display: 'flex', flexDirection: 'column', margin: 14 }}>
                    <Typography
                        variant="description"
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
                            fontSize: { xs: 16, sm: 18, md: 18, lg: 20, xl: 20 }
                        }}>
                        Panadería y Pastelería</Typography>
                    <Typography
                        variant="title"
                        sx={{
                            display: { xs: 'block', sm: 'block', md: 'block', lg: 'block', xl: 'block' },
                            fontSize: { xs: 24, sm: 24, md: 28, lg: 32, xl: 32 }
                        }}>
                        El Rosal</Typography>
                    <Divider light color="red" />
                    <Typography
                        variant="subDescription"
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
                            fontSize: { xs: 12, sm: 16, md: 16, lg: 16, xl: 16 }
                        }}>
                        Lo mejor en Pan y Ponques</Typography>
                </div>
            </ThemeProvider>)
            }

        </Box>
    );
}

export { HomeLogoUI }