import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AppUI } from "./AppUI";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useRef } from "react";
import { NavLink } from "react-router-dom";



function App() {

  const message = 'Hola, estoy interesado/a en realizar un pedido de torta en la Panadería El Rosal. ¿Podrían por favor proporcionarme información sobre los sabores disponibles, tamaños, precios y opciones de entrega? ¡Gracias!'
  const url = `https://api.whatsapp.com/send?phone=573128031680&text=${encodeURIComponent(message)}`;
  
  const iconStyle = {
    color: 'white',
    width: '40px',
    height: '40px',
  };

  const buttonStyle = {
    backgroundColor: '#0df053',
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
  };


  return (
    <React.Fragment>
      <BrowserRouter>
        <Box style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }} >
          <IconButton style={buttonStyle}>
            <NavLink to={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <WhatsAppIcon style={{ ...iconStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </NavLink>
          </IconButton>
        </Box>
        <AppUI />
      </BrowserRouter>
    </React.Fragment>
  )

}

export default App;
