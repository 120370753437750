import React from "react";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel'
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { COLORS } from "../../../assets/colors";
import { NavLink } from "react-router-dom";
import { ROUTES_PATH } from "../../../utils/Consts"

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'initial',
    boxShadow: "none",
    marginLeft: 12,
    backgroundColor: COLORS.primary,
    color: '#FFD700',
    cursor: "pointer"
}));




const ItemCarrousel = (props) => {
    return (
        <img src={props.item} alt={props.id}
            style={{ width: "100%", height: "500px" }} loading="lazy" />
    )
}

function HomeTopCategoriesUI({
    data
}) {
    const matches = useMediaQuery('(min-width:600px)');

    const gridHeight = matches ? '500px' : '450px';

    const categoriesHeight = matches ? '500px' : '300px';

    const imageHeight = matches ? '200px' : '100px';

    return (
        <Box sx={{
            flexGrow: 1,
            padding: { xs: '10px 20px 80px 20px', sm: '10px 20px 80px 20px', md: '10px 20px 10px 20px', lg: '10px 20px 10px 20px', xl: '10px 20px 10px 20px' }
        }} >
            <Typography variant="title" component="h2"
                sx={{
                    color: COLORS.blue, fontFamily: 'Quicksand',
                    paddingBottom: '20px'
                }}>
                Top Categorias
            </Typography>
            {
                data.map(category => (
                    <Grid container key={category.id}>
                        <Grid item xs={5} md={2} sx={{ height: gridHeight, backgroundColor: COLORS.primary }}>
                            <Typography variant="title" component="h2"
                                sx={{
                                    fontFamily: 'Quicksand',
                                    color: 'transparent',
                                    fontWeight: 'bold',
                                    backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                    WebkitBackgroundClip: 'text',
                                    backgroundClip: 'text',
                                    animation: 'shine 5s linear infinite',
                                    padding: '10px 0px 10px 20px'
                                }}>
                                {category.name}
                            </Typography>
                            {
                                category.subCategories.map(subcategory => (
                                    <NavLink
                                        to={{
                                            pathname: `${ROUTES_PATH.CATEGORIES}/${category.id}`,
                                            search: `?subCategoryId=${subcategory.id}&subCategoryName=${encodeURIComponent(subcategory.name)}`,
                                        }}
                                        style={{
                                            textDecoration: 'none',
                                            fontFamily: 'Quicksand',
                                            backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                            WebkitBackgroundClip: 'text',
                                            backgroundClip: 'text',
                                            animation: 'shine 5s linear infinite',
                                        }}
                                        key={subcategory.id}>
                                        <Item key={subcategory.id}>{subcategory.name}</Item>
                                    </NavLink>
                                ))
                            }
                            <NavLink
                                to={`${ROUTES_PATH.CATEGORIES}/${category.id}`}
                                style={{ textDecoration: 'none' }}
                                key={category.id}>
                                <Typography variant="description" component="h4"
                                    sx={{
                                        fontFamily: 'Quicksand',
                                        color: 'transparent',
                                        fontWeight: 'bold',
                                        backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        animation: 'shine 5s linear infinite',
                                        padding: '30px 0px 10px 20px',
                                        cursor: "pointer"
                                    }}>
                                    Ver más
                                </Typography>
                            </NavLink>
                        </Grid>
                        <Grid item xs={0} md={3} sx={{ height: "450px", display: { xs: 'none', md: 'block' } }}>
                            <Carousel indicatorContainerProps={{}} >
                                {
                                    category.carousel.map((item, i) => <ItemCarrousel key={i} item={item} />)
                                }
                            </Carousel>
                        </Grid>
                        <Grid item xs={7} md={7} sx={{ height: categoriesHeight }}>
                            <Grid container spacing={1} sx={{ height: "100%" }}>
                                {
                                    category.products.slice(0, !matches ? 3 : category.products.length).map(products => (
                                        <Grid key={products.id} sx={{ height: "51%" }} item xs={12} md={4}>
                                            <NavLink
                                                to={{
                                                    pathname: `${ROUTES_PATH.CATEGORIES}/${products.categoryId}`,
                                                    search: `?subCategoryId=${products.subCategoryId}&subCategoryName=${encodeURIComponent(products.subcategoryName)}`,
                                                }}
                                                style={{ textDecoration: 'none' }}
                                                key={products.subCategoryId}>
                                                <Item sx={{
                                                    height: "100%",
                                                    display: "grid",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    transition: "box-shadow 0.3s ease-in-out",
                                                    textAlign: 'initial',
                                                    boxShadow: "none",
                                                    backgroundColor: 'white',
                                                    color: 'transparent',
                                                    boxShadow: "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        boxShadow: "0px 4px 14px rgba(0.15, 0.15, 0.15, 0.15)"
                                                    }
                                                }}>
                                                    <Typography variant="title" component="h3" sx={{
                                                        color: 'transparent',
                                                        fontFamily: 'Quicksand',
                                                        fontWeight: 'bold',
                                                        textAlign: "center",
                                                        backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                                        WebkitBackgroundClip: 'text',
                                                        backgroundClip: 'text',
                                                        animation: 'shine 5s linear infinite',
                                                    }}>
                                                        {products.name}
                                                    </Typography>
                                                    <img src={products.image} loading="lazy" style={{ width: imageHeight, height: imageHeight }} />
                                                </Item>
                                            </NavLink>

                                        </Grid>

                                    ))
                                }
                            </Grid>

                        </Grid>


                    </Grid>
                ))
            }

        </Box>
    );
}

export { HomeTopCategoriesUI }; 