import React from "react";
import { HomeCategoriesUI } from "./HomeCategoriesUI";
import { useFetchGet } from "../../../hooks";


function HomeCategories() {
    const { data, isLoading } = useFetchGet('https://app-nuokwylgla-uc.a.run.app/api/v1/categories');
    if (!isLoading) {
        return (
            <HomeCategoriesUI
                data={data}
            />
        );
    }
}

export { HomeCategories }