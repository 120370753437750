
export const ROUTES_PATH = {
    HOME: "/",
    CATEGORIES: "/categorias",
    ABOUT: "/nosostros",
    PRODUCTS: "/productos",
    PRIVACYPOLICY: "/politicas-de-privacidad",
    ALL: "*"
}

export const ITEMS_MENU = {
    HOME: "Inicio",
    ABOUT: "Sobre Nosotros",
    PRODUCTS: "Productos"
}

export const routesMenu = [{
    text: ITEMS_MENU.HOME,
    to: ROUTES_PATH.HOME
},{
    text: ITEMS_MENU.ABOUT,
    to: ROUTES_PATH.ABOUT
}]

export const routes = [{
    text: ITEMS_MENU.PRODUCTS,
    to: ROUTES_PATH.PRODUCTS,
}]