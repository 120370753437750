import React from "react";
import { COLORS } from "../../../assets/colors";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Box } from "@mui/material";
import { PaginationUI } from "../../Pagination/PaginationUI";
import { CategoriesModal } from "../CategoriesModal";
import { useFetchGet } from "../../../hooks";



const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'initial',
    boxShadow: "none",
    marginLeft: 12,
    backgroundColor: 'white',
    color: COLORS.secondary,
    cursor: "pointer"
}));

function CategoriesProductsUI({
    propSubCategoryId,
    propSubCategoryName
}) {

    const [open, setOpen] = React.useState(false);
    const [selectedProductIndex, setSelectedProductIndex] = React.useState(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const handleOpen = (index) => {
        setSelectedProductIndex(index);
        setOpen(true);
    };
    const { data, isLoading } = useFetchGet(`https://app-nuokwylgla-uc.a.run.app/api/v1/products/${propSubCategoryId}?page=${currentPage}`);

    if (!isLoading && data.statusCode === 200) {
        return (
            <div>
                <Box sx={{ paddingBottom: { xs: '50px', sm: '50px', md: '0px', lg: '0px', xl: '0px' } }}>
                    <Typography variant="title" component="h1"
                        sx={{
                            color: COLORS.blue,
                            fontFamily: 'Quicksand',
                            fontWeight: 'bold',
                            justifyContent: 'start',
                            alignItems: 'center',
                            display: 'flex',
                            paddingLeft: { xs: '20px', sm: '20px', md: '60px', lg: '60px', xl: '60px' },
                        }}>
                        {propSubCategoryName}
                    </Typography>
                    <Grid item xs={12} sx={{ padding: { xs: "20px 20px 10px 10px", sm: "20px 20px 10px 10px", md: "20px 50px 20px 50px", lg: "20px 50px 20px 50px", xl: "20px 50px 20px 50px" } }}>
                        <Grid container spacing={1} sx={{ height: "100%" }}>
                            {
                                data.products.map((product, index) => (
                                    <Grid item key={product.id} xs={6} md={3} xl={2} sx={{ height: "50%" }}>
                                        <Item onClick={() => handleOpen(index)} sx={{
                                            height: { xs: "200px", sm: "200px", md: "300px", lg: "300px", xl: "300px" },
                                            display: "grid",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            position: "relative",
                                            boxShadow: "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)",
                                            "&:hover": {
                                                boxShadow: "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)"
                                            }
                                        }}>
                                            <img src={product.image} loading="lazy" style={{
                                                width: "100%",
                                                maxHeight: "100%",
                                                margin: "auto",
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                padding: "5px"

                                            }} />
                                        </Item>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>

                    <PaginationUI
                        totalPages={data.totalPages}
                        setCurrentPage={setCurrentPage}
                    />
                </Box>
                {selectedProductIndex !== null && (
                    <CategoriesModal
                        open={open}
                        setOpen={setOpen}
                        data={data.products[selectedProductIndex]}
                    />
                )}

            </div>
        );
    }

}

export { CategoriesProductsUI };