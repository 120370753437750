import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { COLORS } from '../../../assets/colors';
import HomeIcon from '@mui/icons-material/Home';
import CakeIcon from '@mui/icons-material/Cake';
import InfoIcon from '@mui/icons-material/Info';
import { HomeSearchUI } from '../HomeSearch/HomeSearchUI';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { ROUTES_PATH } from '../../../utils/Consts';


function HomeBottomNavigationUI() {
    const [value, setValue] = React.useState(1);
    const ref = React.useRef(null);
    const navigate = useNavigate();
    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
    }, [value]);

    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />
            <AppBar position="fixed">
                <HomeSearchUI />
            </AppBar>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    style={{ backgroundColor: COLORS.primary }}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction
                        label="Inicio"
                        sx={{
                            color: COLORS.secondary, '&.Mui-selected': { color: 'white' }
                        }}
                        icon={<HomeIcon sx={{
                            color: value === 0 ? 'white' : COLORS.secondary,
                            '&.Mui-selected': {
                                color: COLORS.secondary,
                            },
                        }} />}
                        onClick={() => navigate(ROUTES_PATH.HOME)}
                    />
                    <BottomNavigationAction
                        label="Productos"
                        sx={{
                            color: COLORS.secondary, '&.Mui-selected': { color: 'white' },
                            display: 'none'
                        }}
                        icon={<CakeIcon
                            sx={{
                                color: value === 1 ? 'white' : COLORS.secondary,
                                '&.Mui-selected': {
                                    color: COLORS.secondary,
                                },
                            }} />}
                        onClick={() => navigate(ROUTES_PATH.PRODUCTS)}
                    />
                    <BottomNavigationAction
                        label="Sobre Nosotros"
                        sx={{
                            color: COLORS.secondary, '&.Mui-selected': { color: 'white' },
                            display: 'none'
                        }}
                        icon={<InfoIcon sx={{
                            color: value === 2 ? 'white' : COLORS.secondary,
                            '&.Mui-selected': {
                                color: COLORS.secondary,
                            },
                        }}
                        />}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

export { HomeBottomNavigationUI }