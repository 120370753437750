import React from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { COLORS } from "../../../assets/colors";
import Typography from '@mui/material/Typography';
import { CategoriesProductsUI } from "../CategoriesProducts/CategoriesProductsUI";
import { useState, useEffect } from 'react';
import './Subcategories.css';
import { useNavigate } from 'react-router-dom';
import { ROUTES_PATH } from "../../../utils/Consts"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const handleMouseDown = (event) => {
    event.preventDefault();
    const lista = document.getElementById("miLista");
    const initialX = event.clientX;
    const initialScrollLeft = lista.scrollLeft;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    function handleMouseMove(event) {
        const distanceX = event.clientX - initialX;
        lista.scrollLeft = initialScrollLeft - distanceX;
    }

    function handleMouseUp() {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    }
}

function SubCategoriesUI({
    data,
    categoryId,
    subCategoryId,
    subCategoryName
}) {
    const [propSubCategoryId, setSubCategoryId] = useState('');
    const [propSubCategoryName, setSubCategoryName] = useState('');
    const navigate = useNavigate();
    const onClickItem = (id) => {
        if (subCategoryId !== id) {
            const name = data.find(subCategory => subCategory.id === id).name;
            setSubCategoryId(id);
            setSubCategoryName(name);
            navigate(`${ROUTES_PATH.CATEGORIES}/${categoryId}?subCategoryId=${id}&subCategoryName=${encodeURIComponent(data.find(subCategory => subCategory.id === id).name)}`);
        }
    };

    useEffect(() => {
        if (subCategoryId !== null) {
            setSubCategoryId(subCategoryId);
            setSubCategoryName(subCategoryName);
        } else {
            setSubCategoryId(data[0].id);
            setSubCategoryName(data[0].name);
        }
    }, [data]);

    return (
        <div>
            <Box sx={{
                flexDirection: 'column'
            }}>
                <Box id="miLista" sx={{
                    display: 'flex',

                    maxWidth: '100vw',
                    overflowX: 'auto',
                    padding: { xs: '20px 20px', sm: '20px 20px', md: '20px 60px', lg: '20px 60px', xl: '20px 60px' }
                }}
                    onMouseDown={handleMouseDown}>
                    <Stack direction="row" spacing={2} >
                        {
                            data.map(subCategories => (
                                <Item key={subCategories.id} sx={{
                                    width: "137px",
                                    height: "200px",
                                    backgroundColor: subCategories.id === propSubCategoryId ? COLORS.blue : 'white',
                                    cursor: "pointer",
                                    transition: "box-shadow 0.3s ease-in-out",
                                    boxShadow:
                                        subCategories.id === propSubCategoryId
                                            ? "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)"
                                            : "0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)",
                                    "&:hover": {
                                        boxShadow: "0px 4px 14px rgba(0.15, 0.15, 0.15, 0.15)"
                                    }
                                }}
                                    onClick={() => onClickItem(subCategories.id)}
                                >
                                    <img src={subCategories.image} loading="lazy" alt={subCategories.name}
                                        style={{ width: '100px', height: '100px' }} />
                                    <Typography variant="title" component="h3"
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            color: 'transparent',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                            WebkitBackgroundClip: 'text',
                                            backgroundClip: 'text',
                                            animation: 'shine 5s linear infinite'
                                        }}>
                                        {subCategories.name}
                                    </Typography>
                                    <Typography variant="description" component="h4"
                                        sx={{ color: subCategories.id === propSubCategoryId ? 'white' : COLORS.blue, fontFamily: 'Quicksand' }}>
                                        26 items
                                    </Typography>
                                </Item>
                            ))
                        }
                    </Stack >
                </Box>
            </Box>
            {(propSubCategoryId !== '') && (
                <CategoriesProductsUI
                    propSubCategoryId={propSubCategoryId}
                    propSubCategoryName={propSubCategoryName}
                />
            )}

        </div>

    )
}

export { SubCategoriesUI };