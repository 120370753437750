import React from "react";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CategoriesHeader } from "./CategoriesHeader";
import { SubCategories } from "./SubCategories";
import { useFetchGet } from "../../hooks";
import { useLocation } from "react-router-dom";
import { NieveEffect } from "../../hooks/NieveEffect";
import { useRef } from "react";

function Categories() {
    const canvasRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const subCategoryId = queryParams.get('subCategoryId');
    const subCategoryName = queryParams.get('subCategoryName');
    const { categoryId } = useParams();
    const { data, isLoading } = useFetchGet(`https://app-nuokwylgla-uc.a.run.app/api/v1/products/category/${categoryId}`);

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    if (!isLoading) {
        return (
            <React.Fragment>
                <NieveEffect canvasRef={canvasRef} />
                <CategoriesHeader
                    data={data.name}
                />
                <SubCategories
                    data={data.subCategories}
                    categoryId={categoryId}
                    subCategoryId={subCategoryId}
                    subCategoryName={subCategoryName} />
                <canvas ref={canvasRef} style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none'
                }} />
            </React.Fragment>
        )
    }
}

export { Categories };