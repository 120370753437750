import React from "react";
import { CategoriesHeaderUI } from "./CategoriesHeaderUI";


function CategoriesHeader({
    data
}) {

    return (
        <CategoriesHeaderUI
            data={data}
        />
    );
}

export { CategoriesHeader };