import { useEffect, useState } from "react";

export const useFetchGet = ( url ) => {

    const [state, setState] = useState({
        data: null,
        isLoading: true,
        hasError: null,
    })

    const getFetch = async () => {

        setState({
            ...state,
            isLoading: true,
        });

        const resp = await fetch(url);
        const data = await resp.json();

        setState({
            data,
            isLoading: false,
            hasError: null,
        });
    }

    useEffect(() => {
        getFetch();
    }, [url])
   
    return {
        data:      state.data,
        isLoading: state.isLoading,
        hasError:  state.hasError,
    };
}

export const useFetchPost = (url, postData) => {
    const [state, setState] = useState({
        data: null,
        isLoading: false,
        hasError: null,
    });

    const sendData = async () => {
        setState({
            ...state,
            isLoading: true,
        });

        try {
            const resp = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postData),
            });

            const data = await resp.json();

            setState({
                data,
                isLoading: false,
                hasError: null,
            });
        } catch (error) {
            setState({
                data: null,
                isLoading: false,
                hasError: error.message || "Hubo un error al procesar la solicitud",
            });
        }
    };

    const executePost = async (data) => {
        await sendData(data);
    };

    return {
        executePost,
        data: state.data,
        isLoading: state.isLoading,
        hasError: state.hasError,
    };
};