import React from "react";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CategoriesImageUI } from "../CategoriesImages/CategoriesImageUI";
import { COLORS } from '../../../assets/colors';
import { NavLink } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '350px', sm: '350px', md: '500px', lg: '500px', xl: '500px' },
    height: { xs: '60%', sm: '60%', md: '60%', lg: '55%', xl: '55%' },
    backgroundColor: 'white',
    boxShadow: 24,
    padding: 4,
    borderRadius: 5
};

function CategoriesModalUI({
    open,
    setOpen,
    data
}) {
    const handleClose = () => setOpen(false);
    const message = `Hola, estoy interesado/a en realizar un pedido del siguiente ponque con nombre: ${data.name} y descripción: ${data.description}. ¿Podrían por favor proporcionarme información sobre los sabores disponibles, tamaños, precios y opciones de entrega? ¡Gracias!`;
    const url = `https://api.whatsapp.com/send?phone=573128031680&text=${encodeURIComponent(message)}`;
    if (data) {
        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box sx={style}>
                            {data.details && data.details.length > 1 && (
                                <CategoriesImageUI
                                    details={data.details}
                                />
                            )}

                            <Typography variant="title" component="h2" sx={{
                                fontFamily: 'Quicksand',
                                fontWeight: 'bold',
                                textAlign: "center",
                                color: COLORS.secondary
                            }}>
                                {data.name}
                            </Typography>
                            <Typography variant="description" component="h3"
                                sx={{
                                    fontFamily: 'Quicksand',
                                    textAlign: "center",
                                    color: COLORS.blue
                                }}>
                                {data.description}
                            </Typography>

                            <IconButton style={{ position: 'absolute', bottom: 0, right: 0, padding: '20px', zIndex: 100 }}>
                                <NavLink to={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                    <WhatsAppIcon style={{ color: '#0df053', width: '40px', height: '40px' }} />
                                </NavLink>
                            </IconButton>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        )
    }

}


export { CategoriesModalUI }