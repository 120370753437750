import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import '../HomeCarrousel/HomeCarrousel.css';

const items = [
    {
        name: "El Rosal",
        image: "https://firebasestorage.googleapis.com/v0/b/el-rosal-177df.appspot.com/o/RosalStorage%2FBanners%2Fel-rosal-2-banner.jpg?alt=media&token=78d681c7-d95a-48da-baf0-c64bc190e4b4",
        description: "El Rosal"
    },
    {
        name: "El Rosal 2",
        image: "https://firebasestorage.googleapis.com/v0/b/el-rosal-177df.appspot.com/o/RosalStorage%2FBanners%2Fel-rosal-1-banner.jpg?alt=media&token=bfd4cb0f-5a54-4056-93b0-b801a2ec44a9",
        description: "El Rosal 2"
    },
    {
        name: "El Rosal 3",
        image: "https://firebasestorage.googleapis.com/v0/b/el-rosal-177df.appspot.com/o/RosalStorage%2FBanners%2Fel-rosal-3-banner.jpg?alt=media&token=e83f74be-31dd-452d-b11a-8756de60d47d",
        description: "El Rosal 3"
    }
]


function HomeCarrouselUI() {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        matches && (
            <Carousel indicatorContainerProps={{}}>
                {
                    items.map((item, i) => <Item key={i} item={item} />)
                }
            </Carousel>

        )
    )
}

function Item(props) {
    return (
        <Paper style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '420px' }}>
            <img
                src={props.item.image}
                alt={props.item.name}
                style={{ width: '100%', maxHeight: '100%', objectFit: 'cover' }}
                loading="lazy"
            />
        </Paper>
    )
}

export { HomeCarrouselUI }