import React from "react";
import { HomeBottomNavigationUI } from "./HomeBottomNavigationUI";



function HomeBottomNavigation() {
    return (
        <HomeBottomNavigationUI />
    );
}

export { HomeBottomNavigation }