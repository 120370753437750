import React from "react";
import { SubCategoriesUI } from "./SubcategoriesUI";


function SubCategories({
    data,
    categoryId,
    subCategoryId,
    subCategoryName
}) {
    return (
        <SubCategoriesUI
            data={data}
            categoryId={categoryId}
            subCategoryId={subCategoryId}
            subCategoryName={subCategoryName}
        />
    )
}


export { SubCategories };