import React from "react";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { COLORS } from "../../../assets/colors";
import Typography from '@mui/material/Typography';
import { NavLink } from "react-router-dom";
import { ROUTES_PATH } from "../../../utils/Consts"

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const handleMouseDown = (event) => {
    event.preventDefault();
    const lista = document.getElementById("miLista");
    const initialX = event.clientX;
    const initialScrollLeft = lista.scrollLeft;

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    function handleMouseMove(event) {
        const distanceX = event.clientX - initialX;
        lista.scrollLeft = initialScrollLeft - distanceX;
    }

    function handleMouseUp() {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    }
}

function HomeCategoriesUI({
    data
}) {
    return (
        <Box sx={{
            flexDirection: 'column',
            padding: { xs: '100px 0px 0px 0px', sm: '100px 0px 0px 0px', md: '0px 0px 0px 0px', lg: '0px 0px 0px 0px', xl: '0px 0px 0px 0px' }
        }}>
            <Typography variant="title" component="h2"
                sx={{ color: COLORS.blue, fontFamily: 'Quicksand', padding: '10px 0px 10px 20px' }}>
                Nuestras Categorias
            </Typography>
            <Box id="miLista" sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                maxWidth: '90vw',
                overflowX: 'hidden',
                padding: '10px 0px 20px 20px'
            }}
                onMouseDown={handleMouseDown}>
                <Stack direction="row" spacing={2} >
                    {
                        data.map(category => (
                            <NavLink
                                to={`${ROUTES_PATH.CATEGORIES}/${category.id}`}
                                style={{ textDecoration: 'none' }}
                                key={category.id}>
                                <Item sx={{
                                    width: "137px",
                                    height: "200px",
                                    backgroundColor: 'white',
                                    cursor: "pointer",
                                    boxShadow:"0px 4px 14px rgba(0.25, 0.25, 0.25, 0.25)",
                                    "&:hover": {
                                        boxShadow: "0px 4px 14px rgba(0.15, 0.15, 0.15, 0.15)"
                                    }
                                }}

                                >
                                    <img src={category.image} loading="lazy" alt="hamburguesa"
                                        style={{ width: '100px', height: '100px' }} />
                                    <Typography variant="title" component="h3"
                                        sx={{
                                            fontFamily: 'Quicksand',
                                            color: 'transparent',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            backgroundImage: 'linear-gradient(90deg, #FFD700, #FFA500)',
                                            WebkitBackgroundClip: 'text',
                                            backgroundClip: 'text',
                                            animation: 'shine 5s linear infinite',
                                        }}>
                                        {category.name}
                                    </Typography>
                                    <Typography variant="description" component="h4"
                                        sx={{ color: COLORS.primary, fontFamily: 'Quicksand' }}>
                                        26 items
                                    </Typography>
                                </Item>
                            </NavLink>
                        ))
                    }
                </Stack >
            </Box>
        </Box>

    )


}

export { HomeCategoriesUI }